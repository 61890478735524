export const Cart = () => import('../../components/globals/Cart.vue' /* webpackChunkName: "components/cart" */).then(c => wrapFunctional(c.default || c))
export const CartItem = () => import('../../components/globals/CartItem.vue' /* webpackChunkName: "components/cart-item" */).then(c => wrapFunctional(c.default || c))
export const Errors = () => import('../../components/globals/Errors.vue' /* webpackChunkName: "components/errors" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/globals/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/globals/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/globals/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const LocationConfirmModal = () => import('../../components/globals/LocationConfirmModal.vue' /* webpackChunkName: "components/location-confirm-modal" */).then(c => wrapFunctional(c.default || c))
export const LocationDropdown = () => import('../../components/globals/LocationDropdown.vue' /* webpackChunkName: "components/location-dropdown" */).then(c => wrapFunctional(c.default || c))
export const LocationDropdownColdState = () => import('../../components/globals/LocationDropdownColdState.vue' /* webpackChunkName: "components/location-dropdown-cold-state" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../components/globals/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const UpsellItem = () => import('../../components/globals/UpsellItem.vue' /* webpackChunkName: "components/upsell-item" */).then(c => wrapFunctional(c.default || c))
export const Upsells = () => import('../../components/globals/Upsells.vue' /* webpackChunkName: "components/upsells" */).then(c => wrapFunctional(c.default || c))
export const IconAdd = () => import('../../components/icons/IconAdd.vue' /* webpackChunkName: "components/icon-add" */).then(c => wrapFunctional(c.default || c))
export const IconArrowDown = () => import('../../components/icons/IconArrowDown.vue' /* webpackChunkName: "components/icon-arrow-down" */).then(c => wrapFunctional(c.default || c))
export const IconArrowRight = () => import('../../components/icons/IconArrowRight.vue' /* webpackChunkName: "components/icon-arrow-right" */).then(c => wrapFunctional(c.default || c))
export const IconBag = () => import('../../components/icons/IconBag.vue' /* webpackChunkName: "components/icon-bag" */).then(c => wrapFunctional(c.default || c))
export const IconCarouselArrowLeft = () => import('../../components/icons/IconCarouselArrowLeft.vue' /* webpackChunkName: "components/icon-carousel-arrow-left" */).then(c => wrapFunctional(c.default || c))
export const IconCarouselArrowRight = () => import('../../components/icons/IconCarouselArrowRight.vue' /* webpackChunkName: "components/icon-carousel-arrow-right" */).then(c => wrapFunctional(c.default || c))
export const IconChangeLocation = () => import('../../components/icons/IconChangeLocation.vue' /* webpackChunkName: "components/icon-change-location" */).then(c => wrapFunctional(c.default || c))
export const IconCheck = () => import('../../components/icons/IconCheck.vue' /* webpackChunkName: "components/icon-check" */).then(c => wrapFunctional(c.default || c))
export const IconCheckFilled = () => import('../../components/icons/IconCheckFilled.vue' /* webpackChunkName: "components/icon-check-filled" */).then(c => wrapFunctional(c.default || c))
export const IconChevronDown = () => import('../../components/icons/IconChevronDown.vue' /* webpackChunkName: "components/icon-chevron-down" */).then(c => wrapFunctional(c.default || c))
export const IconChevronDownSmall = () => import('../../components/icons/IconChevronDownSmall.vue' /* webpackChunkName: "components/icon-chevron-down-small" */).then(c => wrapFunctional(c.default || c))
export const IconChevronRight = () => import('../../components/icons/IconChevronRight.vue' /* webpackChunkName: "components/icon-chevron-right" */).then(c => wrapFunctional(c.default || c))
export const IconClose = () => import('../../components/icons/IconClose.vue' /* webpackChunkName: "components/icon-close" */).then(c => wrapFunctional(c.default || c))
export const IconCloseGray = () => import('../../components/icons/IconCloseGray.vue' /* webpackChunkName: "components/icon-close-gray" */).then(c => wrapFunctional(c.default || c))
export const IconCloseGreen = () => import('../../components/icons/IconCloseGreen.vue' /* webpackChunkName: "components/icon-close-green" */).then(c => wrapFunctional(c.default || c))
export const IconClosePurple = () => import('../../components/icons/IconClosePurple.vue' /* webpackChunkName: "components/icon-close-purple" */).then(c => wrapFunctional(c.default || c))
export const IconCurbside = () => import('../../components/icons/IconCurbside.vue' /* webpackChunkName: "components/icon-curbside" */).then(c => wrapFunctional(c.default || c))
export const IconDelivery = () => import('../../components/icons/IconDelivery.vue' /* webpackChunkName: "components/icon-delivery" */).then(c => wrapFunctional(c.default || c))
export const IconDirections = () => import('../../components/icons/IconDirections.vue' /* webpackChunkName: "components/icon-directions" */).then(c => wrapFunctional(c.default || c))
export const IconDoorDash = () => import('../../components/icons/IconDoorDash.vue' /* webpackChunkName: "components/icon-door-dash" */).then(c => wrapFunctional(c.default || c))
export const IconDoorDashFull = () => import('../../components/icons/IconDoorDashFull.vue' /* webpackChunkName: "components/icon-door-dash-full" */).then(c => wrapFunctional(c.default || c))
export const IconDropdown = () => import('../../components/icons/IconDropdown.vue' /* webpackChunkName: "components/icon-dropdown" */).then(c => wrapFunctional(c.default || c))
export const IconEdit = () => import('../../components/icons/IconEdit.vue' /* webpackChunkName: "components/icon-edit" */).then(c => wrapFunctional(c.default || c))
export const IconEllipses = () => import('../../components/icons/IconEllipses.vue' /* webpackChunkName: "components/icon-ellipses" */).then(c => wrapFunctional(c.default || c))
export const IconExit = () => import('../../components/icons/IconExit.vue' /* webpackChunkName: "components/icon-exit" */).then(c => wrapFunctional(c.default || c))
export const IconFacebook = () => import('../../components/icons/IconFacebook.vue' /* webpackChunkName: "components/icon-facebook" */).then(c => wrapFunctional(c.default || c))
export const IconFilter = () => import('../../components/icons/IconFilter.vue' /* webpackChunkName: "components/icon-filter" */).then(c => wrapFunctional(c.default || c))
export const IconGiftCard = () => import('../../components/icons/IconGiftCard.vue' /* webpackChunkName: "components/icon-gift-card" */).then(c => wrapFunctional(c.default || c))
export const IconGlutenFree = () => import('../../components/icons/IconGlutenFree.vue' /* webpackChunkName: "components/icon-gluten-free" */).then(c => wrapFunctional(c.default || c))
export const IconHandoffArrow = () => import('../../components/icons/IconHandoffArrow.vue' /* webpackChunkName: "components/icon-handoff-arrow" */).then(c => wrapFunctional(c.default || c))
export const IconHeart = () => import('../../components/icons/IconHeart.vue' /* webpackChunkName: "components/icon-heart" */).then(c => wrapFunctional(c.default || c))
export const IconHeartFilled = () => import('../../components/icons/IconHeartFilled.vue' /* webpackChunkName: "components/icon-heart-filled" */).then(c => wrapFunctional(c.default || c))
export const IconInstagram = () => import('../../components/icons/IconInstagram.vue' /* webpackChunkName: "components/icon-instagram" */).then(c => wrapFunctional(c.default || c))
export const IconLinkArrow = () => import('../../components/icons/IconLinkArrow.vue' /* webpackChunkName: "components/icon-link-arrow" */).then(c => wrapFunctional(c.default || c))
export const IconLocate = () => import('../../components/icons/IconLocate.vue' /* webpackChunkName: "components/icon-locate" */).then(c => wrapFunctional(c.default || c))
export const IconLocation = () => import('../../components/icons/IconLocation.vue' /* webpackChunkName: "components/icon-location" */).then(c => wrapFunctional(c.default || c))
export const IconLocationMarker = () => import('../../components/icons/IconLocationMarker.vue' /* webpackChunkName: "components/icon-location-marker" */).then(c => wrapFunctional(c.default || c))
export const IconLogoAmex = () => import('../../components/icons/IconLogoAmex.vue' /* webpackChunkName: "components/icon-logo-amex" */).then(c => wrapFunctional(c.default || c))
export const IconLogoDiscover = () => import('../../components/icons/IconLogoDiscover.vue' /* webpackChunkName: "components/icon-logo-discover" */).then(c => wrapFunctional(c.default || c))
export const IconLogoMasterCard = () => import('../../components/icons/IconLogoMasterCard.vue' /* webpackChunkName: "components/icon-logo-master-card" */).then(c => wrapFunctional(c.default || c))
export const IconLogoVisa = () => import('../../components/icons/IconLogoVisa.vue' /* webpackChunkName: "components/icon-logo-visa" */).then(c => wrapFunctional(c.default || c))
export const IconMenu = () => import('../../components/icons/IconMenu.vue' /* webpackChunkName: "components/icon-menu" */).then(c => wrapFunctional(c.default || c))
export const IconMenuClose = () => import('../../components/icons/IconMenuClose.vue' /* webpackChunkName: "components/icon-menu-close" */).then(c => wrapFunctional(c.default || c))
export const IconMinus = () => import('../../components/icons/IconMinus.vue' /* webpackChunkName: "components/icon-minus" */).then(c => wrapFunctional(c.default || c))
export const IconPatio = () => import('../../components/icons/IconPatio.vue' /* webpackChunkName: "components/icon-patio" */).then(c => wrapFunctional(c.default || c))
export const IconPencil = () => import('../../components/icons/IconPencil.vue' /* webpackChunkName: "components/icon-pencil" */).then(c => wrapFunctional(c.default || c))
export const IconPet = () => import('../../components/icons/IconPet.vue' /* webpackChunkName: "components/icon-pet" */).then(c => wrapFunctional(c.default || c))
export const IconPhone = () => import('../../components/icons/IconPhone.vue' /* webpackChunkName: "components/icon-phone" */).then(c => wrapFunctional(c.default || c))
export const IconPickup = () => import('../../components/icons/IconPickup.vue' /* webpackChunkName: "components/icon-pickup" */).then(c => wrapFunctional(c.default || c))
export const IconPickupSmall = () => import('../../components/icons/IconPickupSmall.vue' /* webpackChunkName: "components/icon-pickup-small" */).then(c => wrapFunctional(c.default || c))
export const IconPin = () => import('../../components/icons/IconPin.vue' /* webpackChunkName: "components/icon-pin" */).then(c => wrapFunctional(c.default || c))
export const IconPlus = () => import('../../components/icons/IconPlus.vue' /* webpackChunkName: "components/icon-plus" */).then(c => wrapFunctional(c.default || c))
export const IconPlusCircle = () => import('../../components/icons/IconPlusCircle.vue' /* webpackChunkName: "components/icon-plus-circle" */).then(c => wrapFunctional(c.default || c))
export const IconQuestion = () => import('../../components/icons/IconQuestion.vue' /* webpackChunkName: "components/icon-question" */).then(c => wrapFunctional(c.default || c))
export const IconSelect = () => import('../../components/icons/IconSelect.vue' /* webpackChunkName: "components/icon-select" */).then(c => wrapFunctional(c.default || c))
export const IconSelectArrow = () => import('../../components/icons/IconSelectArrow.vue' /* webpackChunkName: "components/icon-select-arrow" */).then(c => wrapFunctional(c.default || c))
export const IconStar = () => import('../../components/icons/IconStar.vue' /* webpackChunkName: "components/icon-star" */).then(c => wrapFunctional(c.default || c))
export const IconTikTok = () => import('../../components/icons/IconTikTok.vue' /* webpackChunkName: "components/icon-tik-tok" */).then(c => wrapFunctional(c.default || c))
export const IconTimer = () => import('../../components/icons/IconTimer.vue' /* webpackChunkName: "components/icon-timer" */).then(c => wrapFunctional(c.default || c))
export const IconTrash = () => import('../../components/icons/IconTrash.vue' /* webpackChunkName: "components/icon-trash" */).then(c => wrapFunctional(c.default || c))
export const IconVegan = () => import('../../components/icons/IconVegan.vue' /* webpackChunkName: "components/icon-vegan" */).then(c => wrapFunctional(c.default || c))
export const IconVegetarian = () => import('../../components/icons/IconVegetarian.vue' /* webpackChunkName: "components/icon-vegetarian" */).then(c => wrapFunctional(c.default || c))
export const IconVegetarianCarrot = () => import('../../components/icons/IconVegetarianCarrot.vue' /* webpackChunkName: "components/icon-vegetarian-carrot" */).then(c => wrapFunctional(c.default || c))
export const IconX = () => import('../../components/icons/IconX.vue' /* webpackChunkName: "components/icon-x" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../components/common/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AppButton = () => import('../../components/common/AppButton.vue' /* webpackChunkName: "components/app-button" */).then(c => wrapFunctional(c.default || c))
export const ArrowScrollContainer = () => import('../../components/common/ArrowScrollContainer.vue' /* webpackChunkName: "components/arrow-scroll-container" */).then(c => wrapFunctional(c.default || c))
export const BlokLink = () => import('../../components/common/BlokLink.vue' /* webpackChunkName: "components/blok-link" */).then(c => wrapFunctional(c.default || c))
export const CarouselSection = () => import('../../components/common/CarouselSection.vue' /* webpackChunkName: "components/carousel-section" */).then(c => wrapFunctional(c.default || c))
export const FeatureCardSection = () => import('../../components/common/FeatureCardSection.vue' /* webpackChunkName: "components/feature-card-section" */).then(c => wrapFunctional(c.default || c))
export const FilterDropdown = () => import('../../components/common/FilterDropdown.vue' /* webpackChunkName: "components/filter-dropdown" */).then(c => wrapFunctional(c.default || c))
export const FormstackFormSection = () => import('../../components/common/FormstackFormSection.vue' /* webpackChunkName: "components/formstack-form-section" */).then(c => wrapFunctional(c.default || c))
export const FullWidthCta = () => import('../../components/common/FullWidthCta.vue' /* webpackChunkName: "components/full-width-cta" */).then(c => wrapFunctional(c.default || c))
export const Grid = () => import('../../components/common/Grid.vue' /* webpackChunkName: "components/grid" */).then(c => wrapFunctional(c.default || c))
export const Hero = () => import('../../components/common/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c))
export const HeroGif = () => import('../../components/common/HeroGif.vue' /* webpackChunkName: "components/hero-gif" */).then(c => wrapFunctional(c.default || c))
export const HeroSlide = () => import('../../components/common/HeroSlide.vue' /* webpackChunkName: "components/hero-slide" */).then(c => wrapFunctional(c.default || c))
export const HeroVideo = () => import('../../components/common/HeroVideo.vue' /* webpackChunkName: "components/hero-video" */).then(c => wrapFunctional(c.default || c))
export const OrderOnlineCategories = () => import('../../components/common/OrderOnlineCategories.vue' /* webpackChunkName: "components/order-online-categories" */).then(c => wrapFunctional(c.default || c))
export const PromoBanner = () => import('../../components/common/PromoBanner.vue' /* webpackChunkName: "components/promo-banner" */).then(c => wrapFunctional(c.default || c))
export const Promos = () => import('../../components/common/Promos.vue' /* webpackChunkName: "components/promos" */).then(c => wrapFunctional(c.default || c))
export const SmallCard = () => import('../../components/common/SmallCard.vue' /* webpackChunkName: "components/small-card" */).then(c => wrapFunctional(c.default || c))
export const StackedInfoSection = () => import('../../components/common/StackedInfoSection.vue' /* webpackChunkName: "components/stacked-info-section" */).then(c => wrapFunctional(c.default || c))
export const TabsSection = () => import('../../components/common/TabsSection.vue' /* webpackChunkName: "components/tabs-section" */).then(c => wrapFunctional(c.default || c))
export const Test = () => import('../../components/common/Test.vue' /* webpackChunkName: "components/test" */).then(c => wrapFunctional(c.default || c))
export const InfoSectionCard = () => import('../../components/common/InfoSection/InfoSectionCard.vue' /* webpackChunkName: "components/info-section-card" */).then(c => wrapFunctional(c.default || c))
export const InfoSectionTabs = () => import('../../components/common/InfoSection/InfoSectionTabs.vue' /* webpackChunkName: "components/info-section-tabs" */).then(c => wrapFunctional(c.default || c))
export const InfoSection = () => import('../../components/common/InfoSection/index.vue' /* webpackChunkName: "components/info-section" */).then(c => wrapFunctional(c.default || c))
export const ContactUs = () => import('../../components/standalone-pages/ContactUs.vue' /* webpackChunkName: "components/contact-us" */).then(c => wrapFunctional(c.default || c))
export const FranchisingForm = () => import('../../components/standalone-pages/FranchisingForm.vue' /* webpackChunkName: "components/franchising-form" */).then(c => wrapFunctional(c.default || c))
export const Legal = () => import('../../components/standalone-pages/Legal.vue' /* webpackChunkName: "components/legal" */).then(c => wrapFunctional(c.default || c))
export const BeerMenu = () => import('../../components/locations/BeerMenu.vue' /* webpackChunkName: "components/beer-menu" */).then(c => wrapFunctional(c.default || c))
export const BeerMenuItem = () => import('../../components/locations/BeerMenuItem.vue' /* webpackChunkName: "components/beer-menu-item" */).then(c => wrapFunctional(c.default || c))
export const DropdownDeliveryForm = () => import('../../components/locations/DropdownDeliveryForm.vue' /* webpackChunkName: "components/dropdown-delivery-form" */).then(c => wrapFunctional(c.default || c))
export const FastFineLocationBanner = () => import('../../components/locations/FastFineLocationBanner.vue' /* webpackChunkName: "components/fast-fine-location-banner" */).then(c => wrapFunctional(c.default || c))
export const FastFineLocationHero = () => import('../../components/locations/FastFineLocationHero.vue' /* webpackChunkName: "components/fast-fine-location-hero" */).then(c => wrapFunctional(c.default || c))
export const LocationButtons = () => import('../../components/locations/LocationButtons.vue' /* webpackChunkName: "components/location-buttons" */).then(c => wrapFunctional(c.default || c))
export const LocationCard = () => import('../../components/locations/LocationCard.vue' /* webpackChunkName: "components/location-card" */).then(c => wrapFunctional(c.default || c))
export const LocationCards = () => import('../../components/locations/LocationCards.vue' /* webpackChunkName: "components/location-cards" */).then(c => wrapFunctional(c.default || c))
export const LocationCarousel = () => import('../../components/locations/LocationCarousel.vue' /* webpackChunkName: "components/location-carousel" */).then(c => wrapFunctional(c.default || c))
export const LocationDetails = () => import('../../components/locations/LocationDetails.vue' /* webpackChunkName: "components/location-details" */).then(c => wrapFunctional(c.default || c))
export const LocationFilters = () => import('../../components/locations/LocationFilters.vue' /* webpackChunkName: "components/location-filters" */).then(c => wrapFunctional(c.default || c))
export const LocationHero = () => import('../../components/locations/LocationHero.vue' /* webpackChunkName: "components/location-hero" */).then(c => wrapFunctional(c.default || c))
export const LocationReviews = () => import('../../components/locations/LocationReviews.vue' /* webpackChunkName: "components/location-reviews" */).then(c => wrapFunctional(c.default || c))
export const LocationSocial = () => import('../../components/locations/LocationSocial.vue' /* webpackChunkName: "components/location-social" */).then(c => wrapFunctional(c.default || c))
export const LocationSpecials = () => import('../../components/locations/LocationSpecials.vue' /* webpackChunkName: "components/location-specials" */).then(c => wrapFunctional(c.default || c))
export const RadarMap = () => import('../../components/locations/RadarMap.vue' /* webpackChunkName: "components/radar-map" */).then(c => wrapFunctional(c.default || c))
export const ReviewCard = () => import('../../components/locations/ReviewCard.vue' /* webpackChunkName: "components/review-card" */).then(c => wrapFunctional(c.default || c))
export const SpecialsCard = () => import('../../components/locations/SpecialsCard.vue' /* webpackChunkName: "components/specials-card" */).then(c => wrapFunctional(c.default || c))
export const CurbsideForm = () => import('../../components/order/CurbsideForm.vue' /* webpackChunkName: "components/curbside-form" */).then(c => wrapFunctional(c.default || c))
export const DeliveryDriverInfo = () => import('../../components/order/DeliveryDriverInfo.vue' /* webpackChunkName: "components/delivery-driver-info" */).then(c => wrapFunctional(c.default || c))
export const DeliveryOrderStatus = () => import('../../components/order/DeliveryOrderStatus.vue' /* webpackChunkName: "components/delivery-order-status" */).then(c => wrapFunctional(c.default || c))
export const FavoriteOrdersSection = () => import('../../components/order/FavoriteOrdersSection.vue' /* webpackChunkName: "components/favorite-orders-section" */).then(c => wrapFunctional(c.default || c))
export const HandoffModeSelectorOld = () => import('../../components/order/HandoffModeSelector-old.vue' /* webpackChunkName: "components/handoff-mode-selector-old" */).then(c => wrapFunctional(c.default || c))
export const HandoffModeSelector = () => import('../../components/order/HandoffModeSelector.vue' /* webpackChunkName: "components/handoff-mode-selector" */).then(c => wrapFunctional(c.default || c))
export const NumberOfPeople = () => import('../../components/order/NumberOfPeople.vue' /* webpackChunkName: "components/number-of-people" */).then(c => wrapFunctional(c.default || c))
export const OrderSummary = () => import('../../components/order/OrderSummary.vue' /* webpackChunkName: "components/order-summary" */).then(c => wrapFunctional(c.default || c))
export const OrderSummaryItemCard = () => import('../../components/order/OrderSummaryItemCard.vue' /* webpackChunkName: "components/order-summary-item-card" */).then(c => wrapFunctional(c.default || c))
export const OrderTimePicker = () => import('../../components/order/OrderTimePicker.vue' /* webpackChunkName: "components/order-time-picker" */).then(c => wrapFunctional(c.default || c))
export const AppliedFilters = () => import('../../components/menu/AppliedFilters.vue' /* webpackChunkName: "components/applied-filters" */).then(c => wrapFunctional(c.default || c))
export const DietaryFilters = () => import('../../components/menu/DietaryFilters.vue' /* webpackChunkName: "components/dietary-filters" */).then(c => wrapFunctional(c.default || c))
export const FastFineHero = () => import('../../components/menu/FastFineHero.vue' /* webpackChunkName: "components/fast-fine-hero" */).then(c => wrapFunctional(c.default || c))
export const FeaturedMenuCategory = () => import('../../components/menu/FeaturedMenuCategory.vue' /* webpackChunkName: "components/featured-menu-category" */).then(c => wrapFunctional(c.default || c))
export const FeaturedMenuNav = () => import('../../components/menu/FeaturedMenuNav.vue' /* webpackChunkName: "components/featured-menu-nav" */).then(c => wrapFunctional(c.default || c))
export const MenuCategory = () => import('../../components/menu/MenuCategory.vue' /* webpackChunkName: "components/menu-category" */).then(c => wrapFunctional(c.default || c))
export const MenuFeaturesSection = () => import('../../components/menu/MenuFeaturesSection.vue' /* webpackChunkName: "components/menu-features-section" */).then(c => wrapFunctional(c.default || c))
export const MenuHero = () => import('../../components/menu/MenuHero.vue' /* webpackChunkName: "components/menu-hero" */).then(c => wrapFunctional(c.default || c))
export const MenuNavigation = () => import('../../components/menu/MenuNavigation.vue' /* webpackChunkName: "components/menu-navigation" */).then(c => wrapFunctional(c.default || c))
export const MenuSearch = () => import('../../components/menu/MenuSearch.vue' /* webpackChunkName: "components/menu-search" */).then(c => wrapFunctional(c.default || c))
export const NonOloMenu = () => import('../../components/menu/NonOloMenu.vue' /* webpackChunkName: "components/non-olo-menu" */).then(c => wrapFunctional(c.default || c))
export const ContactUsHero = () => import('../../components/contact/ContactUsHero.vue' /* webpackChunkName: "components/contact-us-hero" */).then(c => wrapFunctional(c.default || c))
export const ContactUsLinkCard = () => import('../../components/contact/ContactUsLinkCard.vue' /* webpackChunkName: "components/contact-us-link-card" */).then(c => wrapFunctional(c.default || c))
export const ContactUsLinks = () => import('../../components/contact/ContactUsLinks.vue' /* webpackChunkName: "components/contact-us-links" */).then(c => wrapFunctional(c.default || c))
export const LocationContactCard = () => import('../../components/contact/LocationContactCard.vue' /* webpackChunkName: "components/location-contact-card" */).then(c => wrapFunctional(c.default || c))
export const LocationsContact = () => import('../../components/contact/LocationsContact.vue' /* webpackChunkName: "components/locations-contact" */).then(c => wrapFunctional(c.default || c))
export const LocationsContactCards = () => import('../../components/contact/LocationsContactCards.vue' /* webpackChunkName: "components/locations-contact-cards" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../components/forms/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryForm = () => import('../../components/forms/CheckoutDeliveryForm.vue' /* webpackChunkName: "components/checkout-delivery-form" */).then(c => wrapFunctional(c.default || c))
export const DateSelect = () => import('../../components/forms/DateSelect.vue' /* webpackChunkName: "components/date-select" */).then(c => wrapFunctional(c.default || c))
export const DefaultForm = () => import('../../components/forms/DefaultForm.vue' /* webpackChunkName: "components/default-form" */).then(c => wrapFunctional(c.default || c))
export const FormField = () => import('../../components/forms/FormField.vue' /* webpackChunkName: "components/form-field" */).then(c => wrapFunctional(c.default || c))
export const InputDefault = () => import('../../components/forms/InputDefault.vue' /* webpackChunkName: "components/input-default" */).then(c => wrapFunctional(c.default || c))
export const LocSelector = () => import('../../components/forms/LocSelector.vue' /* webpackChunkName: "components/loc-selector" */).then(c => wrapFunctional(c.default || c))
export const HalfAndHalfOptions = () => import('../../components/product/HalfAndHalfOptions.vue' /* webpackChunkName: "components/half-and-half-options" */).then(c => wrapFunctional(c.default || c))
export const ModifierOption = () => import('../../components/product/ModifierOption.vue' /* webpackChunkName: "components/modifier-option" */).then(c => wrapFunctional(c.default || c))
export const ModifierOptions = () => import('../../components/product/ModifierOptions.vue' /* webpackChunkName: "components/modifier-options" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/product/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductChoices = () => import('../../components/product/ProductChoices.vue' /* webpackChunkName: "components/product-choices" */).then(c => wrapFunctional(c.default || c))
export const ProductDetails = () => import('../../components/product/ProductDetails.vue' /* webpackChunkName: "components/product-details" */).then(c => wrapFunctional(c.default || c))
export const ProductInfo = () => import('../../components/product/ProductInfo.vue' /* webpackChunkName: "components/product-info" */).then(c => wrapFunctional(c.default || c))
export const ProductModifier = () => import('../../components/product/ProductModifier.vue' /* webpackChunkName: "components/product-modifier" */).then(c => wrapFunctional(c.default || c))
export const ProductModifiers = () => import('../../components/product/ProductModifiers.vue' /* webpackChunkName: "components/product-modifiers" */).then(c => wrapFunctional(c.default || c))
export const QuickAddModal = () => import('../../components/product/QuickAddModal.vue' /* webpackChunkName: "components/quick-add-modal" */).then(c => wrapFunctional(c.default || c))
export const SideModifierOption = () => import('../../components/product/SideModifierOption.vue' /* webpackChunkName: "components/side-modifier-option" */).then(c => wrapFunctional(c.default || c))
export const SideModifierOptions = () => import('../../components/product/SideModifierOptions.vue' /* webpackChunkName: "components/side-modifier-options" */).then(c => wrapFunctional(c.default || c))
export const SideOptions = () => import('../../components/product/SideOptions.vue' /* webpackChunkName: "components/side-options" */).then(c => wrapFunctional(c.default || c))
export const SideProductModifier = () => import('../../components/product/SideProductModifier.vue' /* webpackChunkName: "components/side-product-modifier" */).then(c => wrapFunctional(c.default || c))
export const Account = () => import('../../components/account/Account.vue' /* webpackChunkName: "components/account" */).then(c => wrapFunctional(c.default || c))
export const AccountAccordion = () => import('../../components/account/AccountAccordion.vue' /* webpackChunkName: "components/account-accordion" */).then(c => wrapFunctional(c.default || c))
export const AccountOfferCard = () => import('../../components/account/AccountOfferCard.vue' /* webpackChunkName: "components/account-offer-card" */).then(c => wrapFunctional(c.default || c))
export const AccountOffers = () => import('../../components/account/AccountOffers.vue' /* webpackChunkName: "components/account-offers" */).then(c => wrapFunctional(c.default || c))
export const AccountOrderCard = () => import('../../components/account/AccountOrderCard.vue' /* webpackChunkName: "components/account-order-card" */).then(c => wrapFunctional(c.default || c))
export const AccountOrderHistory = () => import('../../components/account/AccountOrderHistory.vue' /* webpackChunkName: "components/account-order-history" */).then(c => wrapFunctional(c.default || c))
export const AccountPayments = () => import('../../components/account/AccountPayments.vue' /* webpackChunkName: "components/account-payments" */).then(c => wrapFunctional(c.default || c))
export const AccountPersonalInfo = () => import('../../components/account/AccountPersonalInfo.vue' /* webpackChunkName: "components/account-personal-info" */).then(c => wrapFunctional(c.default || c))
export const AccountSavedAddressCard = () => import('../../components/account/AccountSavedAddressCard.vue' /* webpackChunkName: "components/account-saved-address-card" */).then(c => wrapFunctional(c.default || c))
export const AccountSavedAddresses = () => import('../../components/account/AccountSavedAddresses.vue' /* webpackChunkName: "components/account-saved-addresses" */).then(c => wrapFunctional(c.default || c))
export const AccountUpdatePassword = () => import('../../components/account/AccountUpdatePassword.vue' /* webpackChunkName: "components/account-update-password" */).then(c => wrapFunctional(c.default || c))
export const CateringDialogue = () => import('../../components/account/CateringDialogue.vue' /* webpackChunkName: "components/catering-dialogue" */).then(c => wrapFunctional(c.default || c))
export const ResetPassword = () => import('../../components/account/ResetPassword.vue' /* webpackChunkName: "components/reset-password" */).then(c => wrapFunctional(c.default || c))
export const SignIn = () => import('../../components/account/SignIn.vue' /* webpackChunkName: "components/sign-in" */).then(c => wrapFunctional(c.default || c))
export const SignUp = () => import('../../components/account/SignUp.vue' /* webpackChunkName: "components/sign-up" */).then(c => wrapFunctional(c.default || c))
export const DefaultMenuSection = () => import('../../components/mellowverse/DefaultMenuSection.vue' /* webpackChunkName: "components/default-menu-section" */).then(c => wrapFunctional(c.default || c))
export const FindAMellow = () => import('../../components/mellowverse/FindAMellow.vue' /* webpackChunkName: "components/find-a-mellow" */).then(c => wrapFunctional(c.default || c))
export const GlimpseIntoMellowverse = () => import('../../components/mellowverse/GlimpseIntoMellowverse.vue' /* webpackChunkName: "components/glimpse-into-mellowverse" */).then(c => wrapFunctional(c.default || c))
export const MellowverseHero = () => import('../../components/mellowverse/MellowverseHero.vue' /* webpackChunkName: "components/mellowverse-hero" */).then(c => wrapFunctional(c.default || c))
export const AllArticles = () => import('../../components/news-and-community/AllArticles.vue' /* webpackChunkName: "components/all-articles" */).then(c => wrapFunctional(c.default || c))
export const ArticleCard = () => import('../../components/news-and-community/ArticleCard.vue' /* webpackChunkName: "components/article-card" */).then(c => wrapFunctional(c.default || c))
export const ArticleInfoSection = () => import('../../components/news-and-community/ArticleInfoSection.vue' /* webpackChunkName: "components/article-info-section" */).then(c => wrapFunctional(c.default || c))
export const ArticleSection = () => import('../../components/news-and-community/ArticleSection.vue' /* webpackChunkName: "components/article-section" */).then(c => wrapFunctional(c.default || c))
export const ArticleSections = () => import('../../components/news-and-community/ArticleSections.vue' /* webpackChunkName: "components/article-sections" */).then(c => wrapFunctional(c.default || c))
export const FeaturedArticles = () => import('../../components/news-and-community/FeaturedArticles.vue' /* webpackChunkName: "components/featured-articles" */).then(c => wrapFunctional(c.default || c))
export const RelatedArticles = () => import('../../components/news-and-community/RelatedArticles.vue' /* webpackChunkName: "components/related-articles" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const CartAccountCheckoutAccountPage = () => import('../../components/cart/account/CheckoutAccountPage.vue' /* webpackChunkName: "components/cart-account-checkout-account-page" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutContainer = () => import('../../components/cart/checkout/Container.vue' /* webpackChunkName: "components/cart-checkout-container" */).then(c => wrapFunctional(c.default || c))
export const CartConfirmContainer = () => import('../../components/cart/confirm/Container.vue' /* webpackChunkName: "components/cart-confirm-container" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsAddPaymentButton = () => import('../../components/cart/checkout/elements/AddPaymentButton.vue' /* webpackChunkName: "components/cart-checkout-elements-add-payment-button" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutCustomer = () => import('../../components/cart/checkout/elements/CheckoutCustomer.vue' /* webpackChunkName: "components/cart-checkout-customer" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutHeader = () => import('../../components/cart/checkout/elements/CheckoutHeader.vue' /* webpackChunkName: "components/cart-checkout-header" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutOffers = () => import('../../components/cart/checkout/elements/CheckoutOffers.vue' /* webpackChunkName: "components/cart-checkout-offers" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutOrder = () => import('../../components/cart/checkout/elements/CheckoutOrder.vue' /* webpackChunkName: "components/cart-checkout-order" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutTips = () => import('../../components/cart/checkout/elements/CheckoutTips.vue' /* webpackChunkName: "components/cart-checkout-tips" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutTotals = () => import('../../components/cart/checkout/elements/CheckoutTotals.vue' /* webpackChunkName: "components/cart-checkout-totals" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsCoupon = () => import('../../components/cart/checkout/elements/Coupon.vue' /* webpackChunkName: "components/cart-checkout-elements-coupon" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsCreditCards = () => import('../../components/cart/checkout/elements/CreditCards.vue' /* webpackChunkName: "components/cart-checkout-elements-credit-cards" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsGiftCards = () => import('../../components/cart/checkout/elements/GiftCards.vue' /* webpackChunkName: "components/cart-checkout-elements-gift-cards" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsGiftCardsForm = () => import('../../components/cart/checkout/elements/GiftCardsForm.vue' /* webpackChunkName: "components/cart-checkout-elements-gift-cards-form" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsGiftCardsNew = () => import('../../components/cart/checkout/elements/GiftCardsNew.vue' /* webpackChunkName: "components/cart-checkout-elements-gift-cards-new" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsGiftCardsStored = () => import('../../components/cart/checkout/elements/GiftCardsStored.vue' /* webpackChunkName: "components/cart-checkout-elements-gift-cards-stored" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsGuestSignUpPrompt = () => import('../../components/cart/checkout/elements/GuestSignUpPrompt.vue' /* webpackChunkName: "components/cart-checkout-elements-guest-sign-up-prompt" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsOffer = () => import('../../components/cart/checkout/elements/Offer.vue' /* webpackChunkName: "components/cart-checkout-elements-offer" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsOloCcsf = () => import('../../components/cart/checkout/elements/OloCcsf.vue' /* webpackChunkName: "components/cart-checkout-elements-olo-ccsf" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsPayInStore = () => import('../../components/cart/checkout/elements/PayInStore.vue' /* webpackChunkName: "components/cart-checkout-elements-pay-in-store" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsPayments = () => import('../../components/cart/checkout/elements/Payments.vue' /* webpackChunkName: "components/cart-checkout-elements-payments" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsSavedCreditCard = () => import('../../components/cart/checkout/elements/SavedCreditCard.vue' /* webpackChunkName: "components/cart-checkout-elements-saved-credit-card" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsSubmitPayment = () => import('../../components/cart/checkout/elements/SubmitPayment.vue' /* webpackChunkName: "components/cart-checkout-elements-submit-payment" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutViewsApp = () => import('../../components/cart/checkout/views/App.vue' /* webpackChunkName: "components/cart-checkout-views-app" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutViewsDefault = () => import('../../components/cart/checkout/views/Default.vue' /* webpackChunkName: "components/cart-checkout-views-default" */).then(c => wrapFunctional(c.default || c))
export const CartConfirmElementsAddressInfo = () => import('../../components/cart/confirm/elements/AddressInfo.vue' /* webpackChunkName: "components/cart-confirm-elements-address-info" */).then(c => wrapFunctional(c.default || c))
export const CartConfirmElementsPhoneInfo = () => import('../../components/cart/confirm/elements/PhoneInfo.vue' /* webpackChunkName: "components/cart-confirm-elements-phone-info" */).then(c => wrapFunctional(c.default || c))
export const CartConfirmElementsPickupIcon = () => import('../../components/cart/confirm/elements/PickupIcon.vue' /* webpackChunkName: "components/cart-confirm-elements-pickup-icon" */).then(c => wrapFunctional(c.default || c))
export const CartConfirmElementsSimpleMap = () => import('../../components/cart/confirm/elements/SimpleMap.vue' /* webpackChunkName: "components/cart-confirm-elements-simple-map" */).then(c => wrapFunctional(c.default || c))
export const CartConfirmViewsDefault = () => import('../../components/cart/confirm/views/Default.vue' /* webpackChunkName: "components/cart-confirm-views-default" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
